import { Text, Box, VStack, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiCircle, BiHeart } from 'react-icons/bi';
import { FaEye, FaHeart } from 'react-icons/fa6';
import { FaRupeeSign } from 'react-icons/fa';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom'

export default function ProductCarouselCardMobile(props) {


    const [hovering, setHovering] = useState(false);
    return (
        <Box
            px={[2, 2, 4, 4]}
        // position='relative'
        >
            <Box
                w={'100%'}
                // h={'100%'}
                bg='gray'
                height={['310px', '310px', '310px', '340px']}
                backgroundImage={props.item.imgUrl}
                // backgroundImage={'/home_4_5.jpg'}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                // w={'100%'}
                position='relative'
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => {
                    setTimeout(() => {
                        setHovering(false)
                    }, 1000);
                    // setHovering(false)
                }}
                onTouchStart={() => setHovering(true)}
                onTouchEnd={() => {
                    setTimeout(() => {
                        setHovering(false)
                    }, 1000);
                }}
            >
                {/* <Text>ProductCarouselCard</Text> */}
            </Box>


            <VStack
                position='absolute'
                bottom={['-10px', '10px', '-2px', '-2px']}
                height={hovering ? ['145px', '145px', '110px', '140px'] : ['110px', '110px', '60px', '110px']}
                overflow={'hidden'}
                bg={'white'}
                // bg={'red'}
                p='4'
                w={'100%'}
                justifyContent={'start'}
                alignItems={'start'}
                transform={hovering ? "translateY(0px)" : 'tranlateY(150px)'}
                transition="all 0.2s ease"
            >
                <Text
                    overflow='ellipsis'
                    height='60px'
                    minHeight='60px'
                    lineHeight='1.3'
                    fontSize={['sm', 'sm', 'md', 'md']}
                >{props.item.name}
                </Text>
                <Text
                    alignItems='baseline'
                    justifyContent='end'
                    h='100%'
                    fontWeight={'800'}
                    color={'#000000'}
                    fontSize={['sm', 'sm', 'lg', 'lg']}
                ><FaRupeeSign style={{ display: 'inline-block' }} size='14' /> {props.item.Price}
                </Text>
                <HStack justifyContent='space-between' w='100%'>
                    <Text as={Link} fontSize='12px' to='product/1'>Select Options</Text>
                    <HStack>
                        <FaEye />
                        <IoHeartOutline />
                    </HStack>
                </HStack>
            </VStack>
        </Box >
    )
}
