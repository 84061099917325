import {
    Flex,
    Button,
    Text,
    VStack,
    HStack,
    Box,
    SimpleGrid,
    Container,
    IconButton,
    useBreakpointValue
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import HomeRowLargeCard from '../../common/HomeRowLargeCard'
// import PlaceSale from '../../common/PlaceSale'
import ProductCard from '../../common/ProductCard'
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6'
// import ReviewCard from '../../common/ReviewCard'
import OutlinedButton from '../../common/OutlinedButton'
import Carousel from "react-multi-carousel";
import ProductCarouselCardMobile from '../../common/ProductCarouselCardMobile'


export default function Home() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            // spacing: 10
            slidesToSlide: 2,
        }
    };


    const responsiveDesktop = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 3,
            arrows: true,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5,
            arrows: true,
        },

    };

    const home_row2_data = [
        {
            imgUrl: '/home_4_1.jpg',
            Price: 2000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_2.jpg',
            Price: 19000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_3.jpg',
            Price: 54000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_4.jpg',
            Price: 34000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_5.jpg',
            Price: 23999,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_1.jpg',
            Price: 2000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_2.jpg',
            Price: 23999,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
        {
            imgUrl: '/home_4_3.jpg',
            Price: 2000,
            name: 'Lorem ipsum odor amet consectetuer adipiscing elit'
        },
    ]

    const home_row4_data = [
        {
            imgUrl: '/home_4_1.jpg',
            Price: 19000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_2.jpg',
            Price: 9000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_3.jpg',
            Price: 12000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_4.jpg',
            Price: 1200,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        },
        {
            imgUrl: '/home_4_5.jpg',
            Price: 23000,
            name: 'Curae vehicula eleifend quis nunc curabitur pharetra vitae'
        }
    ]

    const home_row3_data = [
        {
            imgUrl: '/home_row3_1.jpg',
            name: 'FESTIVAL WORLD'
        },
        {
            imgUrl: '/home_row3_2.jpg',
            name: 'EVERY MOOD'
        },
        {
            imgUrl: '/home_row3_3.jpg',
            name: 'SUMMER WEAR'
        },
        {
            imgUrl: '/home_row3_1.jpg',
            name: 'SAMPURNA'
        }
    ]

    const desktopProductsScroll = useRef();
    const desktopCelebrityClosetScroll = useRef();
    const isThisMobile = useBreakpointValue(
        {
            base: true,
            md: true,
            lg: false,
            xl: false,
        },
    )

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button onClick={() => onClick()} >  Left </button>;
    };

    var productsCarouselDeskTopRef = useRef();

    return (
        <Flex
            flexDirection={'column'}
            justifyContent={'space-between'} alignItems={'center'}
            bg={'#FFFFFF'}
        >
            <Box
                // backgroundImage='/home_banner.png'
                backgroundImage='/home_4_5.jpg'
                maxH='95vh'
                height='95vh'
                width='100%'
                backgroundSize='cover'
                backgroundPosition='center'
                backgroundRepeat='no-repeat'
            />

            <Container maxW='9xl' py='6'>

                <VStack w='100%' spacing='4'>

                    {/* Mobile Carousel Products  */}
                    {isThisMobile &&
                        <Box
                            w='100%'
                            // bg='red'
                            h='320px'
                        >
                            <Carousel responsive={responsive} arrows={false}>
                                {
                                    home_row2_data.map((item, index) => {
                                        return <ProductCarouselCardMobile item={item} key={index} />
                                    })
                                }
                            </Carousel>
                        </Box>
                    }

                    {/* DeskTop  */}

                    <HStack
                        display={['none', 'none', 'none', 'flex']}
                        w='100%'
                    >
                        <IconButton
                            variant='ghost'
                            mx='16'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                const scrollAmount = desktopProductsScroll.current.clientWidth * 0.2;
                                desktopProductsScroll.current.scrollLeft += scrollAmount;
                                if (desktopProductsScroll.current.scrollLeft === desktopProductsScroll.current.scrollWidth - desktopProductsScroll.current.clientWidth) {
                                    desktopProductsScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowLeftLong size='24' />}
                        />
                        <HStack
                            scrollBehavior={'smooth'}
                            ref={desktopProductsScroll}
                            // bg='red'
                            w='100%'
                            overflowX='auto'
                            spacing='11'
                            style={{
                                // hide scrollbar 
                                scrollbarWidth: 'none',
                            }}
                        >
                            {
                                home_row2_data.map((item, index) => {
                                    return <ProductCard item={item} key={index} />
                                })
                            }
                        </HStack>
                        <IconButton
                            mx='16'
                            variant='ghost'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                // make the scroll to be 20% of the ref width 
                                const scrollAmount = desktopProductsScroll.current.clientWidth * 0.2;
                                desktopProductsScroll.current.scrollLeft += scrollAmount;
                                if (desktopProductsScroll.current.scrollLeft === desktopProductsScroll.current.scrollWidth - desktopProductsScroll.current.clientWidth) {
                                    desktopProductsScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowRightLong size='24' />}
                        />
                    </HStack>

                    <SimpleGrid columns={[1, 1, 1, 1, 2]} w='100%' spacing={4} px={['0', '0', '40px', '12%']}>
                        {
                            home_row3_data.map((item, index) => {
                                return <HomeRowLargeCard item={item} key={index} />
                            })
                        }
                    </SimpleGrid>


                    <HStack
                        display={['none', 'none', 'none', 'flex']}
                        w='75%'
                        alignItems='center'
                        justifyContent='center'
                        my='8'
                    // bg='red'
                    >

                        <HStack
                            // bg='blue'
                            width='100%'
                            spacing='12'
                        >

                            <VStack minW='400px' spacing='4' h='100%' alignItems='start' justifyContent='start'>
                                <Text fontSize='3xl'>SUMMER WEAR</Text>
                                <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                                </Text>
                                <Text fontSize='md'>t reverently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha a pre-festive collection tha.
                                </Text>
                                <Text fontSize='md'>rently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha a.
                                </Text>
                                <Text fontSize='md'>Lorem Ipsum Silsila, a prently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha aence of Indian celebrations.
                                </Text>
                                <Text fontSize='md'>rently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha a.
                                </Text>
                            </VStack>

                            <ProductCard item={home_row2_data[0]} />
                            <ProductCard item={home_row2_data[1]} />
                            <ProductCard item={home_row2_data[2]} />
                        </HStack>
                    </HStack>

                    {/* <HStack
                        display={['none', 'none', 'none', 'flex']}
                        width='90%'
                        spacing='8'
                    >
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[0]} />
                        <ProductCard item={home_row2_data[1]} />
                        <ProductCard item={home_row2_data[2]} />
                    </HStack> */}

                    {/* Mobile Screen  */}

                    <VStack display={['flex', 'flex', 'flex', 'none']} mt='4' spacing='4' h='100%' alignItems='start' justifyContent='start'>
                        <Text fontSize='3xl'>SUMMER WEAR</Text>
                        <Text fontSize='md'>Introducing Silsila, a pre-festive collection that reverently captures the essence of Indian celebrations.
                        </Text>
                        <Text fontSize='md'>t reverently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha a pre-festive collection tha.
                        </Text>
                        <Text fontSize='md'>rently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha a.
                        </Text>
                        <Text fontSize='md'>Lorem Ipsum Silsila, a prently captures the essence of Indian celebrations Introducing Silsila, Introducing Silsila, a pre-festive collection tha aence of Indian celebrations.
                        </Text>
                    </VStack>

                    {/* Mobile Carousel Products  */}
                    {isThisMobile &&
                        <Box
                            w='100%'
                            // bg='red'
                            h='320px'
                        >
                            <Carousel responsive={responsive} arrows={false}>
                                {
                                    home_row2_data.map((item, index) => {
                                        return <ProductCarouselCardMobile item={item} key={index} />
                                    })
                                }
                            </Carousel>
                        </Box>
                    }

                    <OutlinedButton>
                        Explore More
                    </OutlinedButton>


                    <Text textAlign={'center'} fontSize={'xx-large'}
                        color={'#966754'}
                        my='6'
                    >
                        CELEBRITY CLOSET
                    </Text>

                    <HStack
                        display={['none', 'none', 'flex', 'flex']}
                        w='100%'
                    >
                        <IconButton
                            variant='ghost'
                            mx='16'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                const scrollAmount = desktopCelebrityClosetScroll.current.clientWidth * 0.2;
                                desktopCelebrityClosetScroll.current.scrollLeft += scrollAmount;
                                if (desktopCelebrityClosetScroll.current.scrollLeft === desktopCelebrityClosetScroll.current.scrollWidth - desktopCelebrityClosetScroll.current.clientWidth) {
                                    desktopCelebrityClosetScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowLeftLong size='24' />}
                        />
                        <HStack
                            scrollBehavior={'smooth'}
                            ref={desktopCelebrityClosetScroll}
                            // bg='red'
                            w='100%'
                            overflowX='auto'
                            spacing='11'
                            style={{
                                // hide scrollbar 
                                scrollbarWidth: 'none',
                            }}
                        >
                            {
                                home_row2_data.map((item, index) => {
                                    return <ProductCard item={item} key={index} />
                                })
                            }
                        </HStack>
                        <IconButton
                            mx='16'
                            variant='ghost'
                            bg='white'
                            _hover={{
                                bg: 'white'
                            }}
                            onClick={() => {
                                // make the scroll to be 20% of the ref width 
                                const scrollAmount = desktopCelebrityClosetScroll.current.clientWidth * 0.2;
                                desktopCelebrityClosetScroll.current.scrollLeft += scrollAmount;
                                if (desktopCelebrityClosetScroll.current.scrollLeft === desktopCelebrityClosetScroll.current.scrollWidth - desktopCelebrityClosetScroll.current.clientWidth) {
                                    desktopCelebrityClosetScroll.current.scrollLeft = 0;
                                }
                            }}
                            icon={<FaArrowRightLong size='24' />}
                        />
                    </HStack>


                    {/* Mobile Version  */}

                    {/* Mobile Carousel Products  */}
                    {isThisMobile &&
                        <Box
                            w='100%'
                            // bg='red'
                            h='320px'
                        >
                            <Carousel responsive={responsive} arrows={false}>
                                {
                                    home_row2_data.map((item, index) => {
                                        return <ProductCarouselCardMobile item={item} key={index} />
                                    })
                                }
                            </Carousel>
                        </Box>
                    }

                    {/* <HStack
                        display={['flex', 'flex', 'none', 'none']}
                        w='100%'
                        p='6'
                        overflowX='auto'
                        spacing='4'
                        style={{
                            // hide scrollbar 
                            scrollbarWidth: 'none',
                        }}
                    >
                        {
                            home_row2_data.map((item, index) => {
                                return <ProductCard item={item} key={index} />
                            })
                        }
                    </HStack> */}

                    {/* <PlaceSale /> */}

                </VStack>

            </Container>
        </Flex>
    )
}
